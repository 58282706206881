import React from "react";
import {useSelector} from "react-redux"
import { useHistory } from "react-router";
import Header from "../Header/Header";
import KeyboardVirtual from "../Keyboard/KeyboardVirtual";
import "./containers.css";
import { isMobile, isTablet } from 'react-device-detect';
import Box from "@material-ui/core/Box";

const MainContainer = ({ children, title, subtitle, icon, formName = "noForm", showKeyboard, back, home, sendEmail }) => {
  const history = useHistory();

  return (
    <div className="mainContainer" style={history.location.pathname == "/" ? {overflowY:"hidden"}  : {}}>
      {showKeyboard && !isTablet ? (<KeyboardVirtual formName={formName} style={{ display: "flex" }}>
        <div className="containerHeaderAvailableAccess">
          <Header showKeyboard={showKeyboard} back={back} home={home} sendEmail={sendEmail} />
        </div>
        <Box display="flex" justifyContent="center" alignItems="center">
          <div className="main-container" style={history.location.pathname !== "/" ? {overflowY:"scroll"}  : {}}>
            <div className="containerTitle">
              {title && <div className="containerTextSuperior">
                {icon && <img className="icon" src={icon} alt="desc" />}
                <span className="title">{title}</span>
              </div>}
              {subtitle && <span className="subtitle">{subtitle}</span>}
            </div>
            <div className="children">{children}</div>
          </div>
        </Box>
      </KeyboardVirtual>) :
        (
          <>
            <div className="containerHeaderAvailableAccess">
              <Header back={back} home={home} sendEmail={sendEmail} />
            </div>
            <Box display="flex" justifyContent="center" alignItems="center" height="75vh">
              <div className="main-container" style={history.location.pathname !== "/" ? {overflowY:"scroll"}  : {}}>
                <div className="containerTitle">
                  {title && <div className="containerTextSuperior">
                    {icon && <img className="icon" src={icon} alt="desc" />}
                    <span className="title">{title}</span>
                  </div>}
                  {subtitle && <span className="subtitle">{subtitle}</span>}
                </div>
                {title && subtitle ? <div className="children">{children}</div> : <div className="children" style={{ height: "95%" }}>{children}</div>}
              </div>
            </Box>
          </>
        )}
    </div>
  );
};

export default MainContainer;
